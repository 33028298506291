jQuery(function($){    
    // SLIDER
    var homeslider = new MasterSlider();
    homeslider.setup('ca-slider' , {
            grabCursor:false,
            autoplay:true,
            loop:true,
            layout:'autofill',
            overPause:false,
            width:1366,
            height:700,
            fillMode:'fill',
            autoHeight:true,
            view:'fade',
            centerControls:false
        });
    homeslider.control('bullets' , {autohide:false  , dir:"h", align:"bottom"});

    // Novidades Carrossel
    $('#ca-novidades-slider').carousel('pause')

    // Menu grudando no topo com a rolagem
    $(window).bind('scroll', function() {
        var navHeight = $( window ).height() - 100;
        if ($(window).scrollTop() > 30) {
            $('#ca-nav').addClass('active');
        }
        else {
            $('#ca-nav').removeClass('active');
        }
    });
    // Scroll suave no menu
    $('#ca-menu-itens a, #ca-logo').bind('click', function(event) {
        var url = '/'+$(this).attr('href')
        var title = $(this).text()
        var menu_id = url.replace('#', '');
        if(title == ''){ title = 'Início'}
        $('html, body').stop().animate({
            scrollTop: ($($(this).attr('href')).offset().top) - 40
        }, 1250, 'easeInOutExpo');
        // removendo a classe de ativo no menu
        $('#ca-menu-itens li').removeClass('active')
        // adicionando ao novo menu
        $(this).parent().addClass('active')
        // previnindo o clique
        event.preventDefault();
    });
    // Fechando o menu no clique pelo celular   
    $('.navbar-collapse ul li a').click(function() {
        $('.navbar-toggle:visible').click();
    });

    // Marcar o menu de acordo com a rolage
    var title_atual = document.title;
    $('body').scrollspy({
        target: '#ca-nav',
        offset: 80
    }).on('activate.bs.scrollspy', function () {
        var menu = $("#ca-nav li.active a");
        var url = '/'+menu.attr('href')
        var title = title_atual + ' - '+ menu.text()
        var menu_id = url.replace('#', '');
        // muda o title
        document.title = title
        // Grava histórico no navegador
        history.pushState(menu_id, title, url);
        // Tenta gerar um pageview no Analytics
        try{
            ga('send', 'pageview', menu_id);
        }catch(err){
            console.log('nao rolou')
        }
    })

    // Máscaras
    $('input[data-toggle="telefone"]').focusout(function(){
        var phone, element;
        element = $(this);
        element.unmask();
        phone = element.val().replace(/\D/g, '');
        if(phone.length > 10) {
            element.mask("(99) 99999-999?9");
        } else {
            element.mask("(99) 9999-9999?9");
        }
    }).trigger('focusout');

    // Modal de Erros
    $('div[data-toggle="modal_aberto"]').modal('show')
        
    // POPUPS
    popup();

    //Galeria Lightgallery
    var portfolioSlider = $('#ca-slider-portfolio').lightSlider({
        gallery:true,
        item:1,
        auto:true,
        loop:true,
        thumbItem:5,
        thumbMargin:5,
        slideMargin:0,
        enableDrag: false,
        currentPagerPosition:'left',
        speed:1000,
        pause:3000,
        mode:'fade',
        easing: 'cubic-bezier(0.25, 0, 0.25, 1)',
        onSliderLoad: function(el) {
            el.lightGallery({
                selector: '#ca-slider-portfolio .lslide'
            });
        }   
    });

    // Carrossel dos clientes
    var clientesSlider = $('#ca-clientes-lista').lightSlider({
        auto:true,
        loop:true,
        pauseOnHover: true,
        slideMove:2,
        autoWidth:true,
        slideMargin:40,
        galleryMargin: 20,
        pause:2500,
        controls:false,
        pager:false,
        easing: 'cubic-bezier(0.25, 0, 0.25, 1)',
        onSliderLoad: function() {
            $('#ca-clientes-lista').removeClass('css-hidden');
        }
    });

    // Carrossel dos certificados
    var certificacoesSlider = $('#ca-lista-certificacoes').lightSlider({
        auto:true,
        loop:true,
        pauseOnHover: true,
        slideMove:2,
        autoWidth:true,
        slideMargin:40,
        galleryMargin: 20,
        pause:2500,
        controls:false,
        pager:false,
        easing: 'cubic-bezier(0.25, 0, 0.25, 1)',
        onSliderLoad: function() {
            $('#ca-lista-certificacoes').removeClass('css-hidden');
        }
    });

    // Scroll bar
    $(".ca-popup-scroll").mCustomScrollbar({
        axis:"y", // horizontal scrollbar
        autoHideScrollbar: false
    });
    // Setando a altura de cada faixa para a altura da tela do usuário.
    var altura_da_tela = window.innerHeight;
    var altura_da_tela_util = window.innerHeight - $('#ca-rodape').height() - $('#ca-nav').height()
    $('#inicio,#quem-somos,#portfolio,#como-fazemos,#novidades,#construindo-ideias').css('min-height', altura_da_tela+'px');
    $('#contato').css('min-height', altura_da_tela_util+'px');

    // Setando a altura de uma div para que a outra fique alinhada
    $('#ca-quem-somos-imagem').css('height', $('#ca-quem-somos-info').height())
    $('#ca-como-fazemos-imagem').css('height', $('#ca-como-fazemos-info').height())
    
    // Setando uma altura para os popups de texto no mobile
    $('.ca-popup-scroll').css('max-height', altura_da_tela_util)
    
    // WOW efeitos
    new WOW().init();

    // paginacao[construindo ideias]
    $(document).on('click', '.pagination a', function (e) {
        e.preventDefault();
        getPosts($(this).attr('href').split('page=')[1]);
    });

    // Form de contato
    $('#ca-contato-form').submit(function(){
        var dados = $( this ).serialize();
        $.ajax({
            type: "POST",
            url: $(this).attr('action'),
            data: dados,
            success: function( data )
            {   
                if(data.status=='ok'){
                    var obrigado = $('#ca-popup-contato-gatilho').magnificPopup({
                        type:'inline',
                        midClick: true,
                        mainClass: 'mfp-fade ca-popup-autowidth'
                    });
                    obrigado.magnificPopup('open');
                    $('#ca-contato-form').trigger("reset");
                }
            }
        });
        
        return false;
    });

});

// + paginacao[construindo ideias]
function getPosts(page) {
    $.ajax({
        url : '?page=' + page,
        dataType: 'json',
    }).done(function (data) {
        $('#construindo_ideias_itens').html(data);
        popup();
    }).fail(function () {
        alert('Ocorreu um erro ao carregar os itens.');
    });
}


// POPUPs
function popup(e){
    $('.ca-popup').magnificPopup({
        type:'inline',
        midClick: true,
        mainClass: 'mfp-fade'
    });
}